<template>
  <div>
    <div class="row" v-if="managePanel === 'login'">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div class="async-error" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="card card-help">
          <div class="card-body">
            <h1>Manage your existing voucher</h1>
            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group id="email-input-group" label-for="email-input">
                <b-form-input
                  id="email-input"
                  name="email-input"
                  type="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  placeholder="Please enter your work email address"
                ></b-form-input>

                <b-form-invalid-feedback
                  >Valid email required</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group id="code-input-group" label-for="code-input">
                <b-form-input
                  id="code-input"
                  name="code-input"
                  v-model="$v.form.code.$model"
                  :state="validateState('code')"
                  placeholder="Please enter your company code"
                ></b-form-input>

                <b-form-invalid-feedback
                  >Company code required</b-form-invalid-feedback
                >
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                class="btn btn-primary btn-block"
                >Submit</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row" v-if="managePanel === 'manage'">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div class="async-error" v-if="manageError">
          {{ manageErrorMessage }}
        </div>
        <div class="card card-help">
          <div class="card-body">
            <h1>Manage your existing voucher</h1>
            <div class="row">
              <div class="col">
                <table class="table">
                  <tr>
                    <td>Status:</td>
                    <td>
                      <span
                        v-if="voucher.status === 'Used'"
                        class="text-danger"
                        >{{ voucher.status }}</span
                      >
                      <span
                        v-else-if="type === 'Active'"
                        class="text-success"
                        >{{ voucher.status }}</span
                      >
                      <span v-else class="text-warning">{{
                        voucher.status
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Assigned:</td>
                    <td>{{ voucher.masked_email }}</td>
                  </tr>
                </table>
              </div>
              <div class="col" v-if="!voucher.pharmacy_used">
                <div
                  class="d-flex justify-content-between align-items-center px-2 pt-1 pb-2"
                  v-if="resendVoucherMessage"
                >
                  <img src="/images/Green-Tick.png" style="max-height: 40px" />
                  <p class="lead p-2 m-0">
                    Your voucher is on its way! Please check your junk folder if
                    it isn't with you in 30 minutes.
                  </p>
                </div>
                <button
                  type="submit"
                  class="btn btn-block bg-hpcolour-green"
                  name="resend"
                  value="resend"
                  @click="resendVoucher"
                  v-if="voucher.status === 'Active'"
                >
                  Resend my voucher
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mt-4">
                  <a
                    class="btn btn-block btn-lg btn-pharmacy btn-flubtn d-flex justify-content-between align-items-center h-flubox"
                    @click="navigatePage('Find')"
                  >
                    <div>
                      <img
                        src="/images/pharmacy-marker.png"
                        class="img-fluid"
                      />
                    </div>
                    <div>Find a pharmacy</div>
                    <div>
                      <img src="/images/chevron.png" class="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import * as CampaignAccessCache from "./../modules/CampaignAccessCache";
import voucherFactory from "./../modules/AssignVoucher";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "Home",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        code: null,
      },
      voucher: {},
      resendVoucherMessage: false,
      managePanel: "login",
      error: false,
      errorMessage: null,
      manageError: false,
      manageErrorMessage: null,
      type: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      code: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params });
        return;
      }
      this.$router.push({ name: page });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.$store.dispatch("campaignDetailsAction", null);
        var campaign = await CampaignAccessCache.getCampaignAccessCacheByCode(
          this.form.code
        );

        const voucher = await voucherFactory(
          campaign,
          this.form.email,
          "manage"
        );

        if (voucher === null) {
          this.errorMessage =
            "Sorry the details you entered are not recognised, please try again";
          this.error = true;
          return;
        }

        if (voucher.code) {
          this.voucher = voucher;
          this.managePanel = "manage";
          voucher.assigned_timestamp = moment(
            voucher.assigned_timestamp,
            "YYYY-MM-DD HH:mm"
          );
          voucher.sent_timestamp = moment(
            voucher.sent_timestamp,
            "YYYY-MM-DD HH:mm"
          );
          const hasVoucherExpired = moment(
            voucher.end_date,
            "DD/MM/YYYY HH:mm"
          ).isAfter(new Date(), "day");
          if (voucher.used) {
            voucher.status = "Used";
            return;
          }
          if (hasVoucherExpired) {
            voucher.status = "Expired";
          } else {
            voucher.status = "Active";
          }
        }
      } catch (e) {
        this.errorMessage = e;
        this.error = true;
        throw new Error(e);
      }
    },
    async resendVoucher() {
      const that = this;
      try {
        const {
          data: { resend_voucher: voucher },
        } = await API.graphql({
          query: queries.resend_voucher,
          variables: {
            email: that.form.email,
            code: that.form.code,
            sender: "flu@healthyperformance.co.uk",
            client: "healthyperformance",
          },
          authMode: "API_KEY",
        });
        if (voucher === null) {
          that.errorMessage = "Sorry the details you entered are not recognised, please try again";
          that.error = true;
          return;
        }
        that.resendVoucherMessage = true;
      } catch (e) {
        // console.log(e, "error");
        that.manageErrorMessage = e;
        that.manageError = true;
        throw new Error(e);
      }
    },
  },
  computed: {
    emailValidation() {
      return /.+@.+/.test(this.form.email);
    },
    validation() {
      return this.form.email.length > 4 && this.form.email.length < 13;
    },
  },
};
</script>
<style scoped>
.async-error {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 150%;
  color: #dc3545;
}
</style>